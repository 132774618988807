import React from 'react';
import { Link } from 'react-router-dom';
import style from './Navigation.css';

class NavigationComponent extends React.Component {
  render() {
    const props = this.props;
    return <div className={style.container}>
      <Link className={props.match.params.filter === 'all' ? style.active : style.link} to="/all">All</Link>
      <Link className={props.match.params.filter === 'done' ? style.active : style.link} to="/done">Done</Link>
      <Link className={(props.match.params.filter === void(0) || props.match.params.filter === 'todo') ? style.active : style.link} to="/todo">ToDo</Link>
    </div>;
  }
}

export default NavigationComponent;
