export const actionUpdateTitle = (value, canSubmit) => ({
  type: 'UPDATE_TITLE_VALUE',
  value,
  canSubmit
});

export const actionUpdateLink = (value, canSubmit) => ({
  type: 'UPDATE_LINK_VALUE',
  value,
  canSubmit
});

export const actionTrySubmit = () => ({
  type: 'TRY_SUBMIT_FORM',
});

export const actionSubmit = () => ({
  type: 'SUBMIT_FORM',
});

export const actionGotResponse = (response) => ({
  type: 'UPDATE_LIST',
  response
});

const callAddItem = (state) => dispatch => {
  dispatch(actionSubmit());

  return fetch('/ajax/AddItem', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({
      title: state.AddItem.title,
      link: state.AddItem.link,
    })
  })
    .then(response => response.json())
    .then(json => dispatch(actionGotResponse(json)));
};

const canAddItem = () => true;

export const addNewItem = () => (dispatch, getState) => {
  if (canAddItem(getState())) {
    return dispatch(callAddItem(getState()));
  }

  return false;
};
