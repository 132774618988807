import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import { connectRouter, routerMiddleware } from 'connected-react-router';
import AddItem from '../components/addItem/AddItem.reducers';
import ToDoList from '../components/toDoList/ToDoList.reducers';
import EditItem from '../components/editItem/EditItem.reducers';
import {createBrowserHistory} from "history";
import thunk from 'redux-thunk';

export const history = createBrowserHistory();

const historyWrapper = (history) => combineReducers({
  AddItem,
  ToDoList,
  EditItem,
  router: connectRouter(history)
});

const dev = process.env.NODE_ENV !== "production";

const composeEnhancers =
  dev && typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(
    thunk,
    // log,
    routerMiddleware(history)
  )
);

export const store = createStore(
  historyWrapper(history),
  enhancer,
);
