import React from 'react';
import style from './EditItem.css';

class EditItemComponent extends React.Component {

  render() {
    if (this.props.item) {
      return this.renderContent();
    }

    return <div></div>;
  }
  renderContent() {
    const props = this.props;
    return <div>
      <div className={style.overlay}>
        <div className={style.content}>
          <form ref={(form) => { this.form = form; }} >
            <div className={style.row}>
              <input type="text" className={style.title} onChange={(event) => { props.onTitleChange(event.target.value, this.form.checkValidity()); } } placeholder="Please enter artist/song" value={props.item.title} />
            </div>
            <div className={style.row}>
              <input type="url" className={style.link} onChange={(event) => { props.onLinkChange(event.target.value, this.form.checkValidity()); } } placeholder="Please enter link" value={props.item.link}/>
            </div>
            <div className={style.row}>
              <button type="button" onClick={() => props.onUpdate(props.item)} className={style.mainButton}>Save</button>
              <button type="button" onClick={props.onClose} className={style.button}>Close</button>
            </div>
          </form>
        </div>
      </div>
    </div>;
  }
}

export default EditItemComponent;
