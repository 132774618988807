export const actionTryLoad = () => ({
  type: 'TRY_LOAD_DATA',
});

export const actionLoadDataDone = (response) => ({
  type: 'LOAD_DATA',
  response
});

export const actionGotResponse = (response) => ({
  type: 'ITEM_UPDATES',
  response
});

export const actionGotRemoveResponse = (response) => ({
  type: 'ITEM_REMOVE',
  response
});


export const actionSubmit = () => ({
  type: 'ITEM_UPDATING'
});

export const actionEdit = (item) => ({
  type: 'ITEM_EDIT',
  item: { ...item }
});

export const actionRequestData = () => ({
  type: 'DATA_LOADING',
});

const callLoadData = (state) => dispatch => {
  dispatch(actionRequestData());

  return fetch('/ajax/GetList', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({
      type: state.ToDoList.state
    })
  })
    .then(response => response.json())
    .then(json => dispatch(actionLoadDataDone(json)));
};

export const actionCallUpdate = (id) => (dispatch) =>
  fetch('/ajax/UpdateItem', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({
      id
    })
  })
    .then(response => response.json())
    .then(json => dispatch(actionGotResponse(json)));

export const actionCallRemove = (id) => (dispatch) =>
  fetch('/ajax/RemoveItem', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({
      id
    })
  })
    .then(response => response.json())
    .then(json => dispatch(actionGotRemoveResponse(json)));

export const loadData = () => (dispatch, getState) => dispatch(callLoadData(getState()));
