import React from 'react';
import style from './ToDoList.css';
import ToDo from '../toDo/ToDo.container';

class ToDoListComponent extends React.Component {
  constructor(props) {
    super();
    props.actionTryLoad();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.doLoad) {
      nextProps.loadData();
    }
  }

  render() {
    const props = this.props;
    const items = props.items.itemList;
    return (
      <ul className={style.container}>
        {
          items.map((item) => {
            let result = '';
            if ((props.match.params.filter === 'all') || (props.match.params.filter === 'done' && item.done) || ((props.match.params.filter === void(0)) || (props.match.params.filter === 'todo' && !item.done))) {
              result = <ToDo key={item._id}
                itemId={item._id}
                {...item}
                onClick = {() => props.actionCallUpdate(item._id)}
                onRemove = {() => props.actionCallRemove(item._id)}
                onEdit = {() => props.actionEdit(item)}
              />;
            }
            return result;
          })
        }
      </ul>
    );
  }
}

export default ToDoListComponent;
