import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ToDoListComponent from './ToDoList.component';
import { loadData, actionTryLoad, actionCallUpdate, actionEdit, actionCallRemove } from './ToDoList.actions';

const mapStateToProps = (state) => ({
  items: state.ToDoList,
  doLoad: state.ToDoList.tryLoadData
});

const mapDispatchToProps = ({
  actionTryLoad,
  loadData,
  actionCallUpdate,
  actionEdit,
  actionCallRemove,
});

const ToDoList = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ToDoListComponent));

export default ToDoList;
