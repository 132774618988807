const EditItem = (state = { item: false, canSubmit: false, trySubmit: false }, action) => {
  switch (action.type) {
    case 'ITEM_EDIT': {
      return {
        ...state,
        item: action.item,
      };
    }
    case 'EDIT_TITLE_VALUE':
      return {
        ...state,
        item: {
          ...state.item,
          title: action.value
        },
        trySubmit: false,
        canSubmit: action.canSubmit,
      };
    case 'ITEM_UPDATES':
      return {
        ...state,
        item: false,
      };
    case 'EDIT_LINK_VALUE':
      return {
        ...state,
        item: {
          ...state.item,
          link: action.value
        },
        trySubmit: false,
        canSubmit: action.canSubmit,
      };
    case 'EDIT_CLOSE':
      return {
        ...state,
        c: console.info('HERE-2'),
        item: false,
        canSubmit: false,
        trySubmit: false,
      };
    default:
      return state;
  }
};

export default EditItem;
