import React from 'react';
import AddItem from '../addItem/AddItem.container';
import ToDoList from '../toDoList/ToDoList.container';
import Navigation from '../navigation/Navigation.container';
import EditItem from '../editItem/EditItem.container';

const App = () => (
    <div>
      <AddItem />
      <Navigation />
      <ToDoList />
      <EditItem />
    </div>
  );

export default App;
