import React from 'react';
import style from './AddItem.css';

class AddItemComponent extends React.Component {

  componentWillReceiveProps(nextProps) {
    if (nextProps.doSubmit) {
      nextProps.addNewItem();
    }
  }

  render() {
    const props = this.props;
    return (
      <form ref={(form) => { this.form = form; }} className={style.container}>
        <input value={props.title} className={style.titleInput} placeholder="Enter Artist name and/or song title" type="text" onChange={(event) => { props.onTitleChange(event.target.value, this.form.checkValidity()); } } />
        <input value={props.link} className={style.linkInput} placeholder="Enter link to artist or song" type="url" onChange={(event) => { props.onLinkChange(event.target.value, this.form.checkValidity()); } } />
        <button className={style.submitItem} type="button" onClick={() => { props.onTrySubmit(); }}>Add item</button>
        <div ref={(error) => { this.error = error; }} className={(props.canSubmit || (props.title === '' && props.link === '')) ? style.error : style.errorDisplay}>Invalid data!</div>
      </form>
    );
  }
}

export default AddItemComponent;
