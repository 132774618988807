import React from 'react';
import style from './ToDo.css';

class ToDoComponent extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.updateRequest) {
      nextProps.doUpdateItem(nextProps);
    }
  }

  render() {
    const props = this.props;
    let link;
    if (props.link !== '') {
      link = <span className={style.listItemLink}><a className={style.link} target="_blank" href={props.link}>link</a></span>;
    }

    return <li className={(props.done ? style.done : style.todo)} key={props._id}>
        <span className={style.editItem}>
          <i className={`fa fa-pencil ${style.editbox}`} title="Edit" onClick={props.onEdit} aria-hidden="true"></i>
          <i title="Done" onClick={props.onClick} className={`fa fa-check ${style.checkbox}`} aria-hidden="true"></i>
          <i className={`fa fa-trash ${style.delbox}`} onClick={props.onRemove} title="Remove" aria-hidden="true"></i>
        </span>
        <span onClick={props.onClick} className={style.listItemTouchable}>{props.title}</span>{link}
      </li>;
  }
}

export default ToDoComponent;
