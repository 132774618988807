import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import EditItemComponent from './EditItem.component';
import { actionEditTitle, actionEditLink, actionClose, actionCallUpdate } from './EditItem.actions';

const mapStateToProps = (state) => ({
  item: state.EditItem.item
});

const mapDispatchToProps = {
  onTitleChange: actionEditTitle,
  onLinkChange: actionEditLink,
  onClose: actionClose,
  onUpdate: actionCallUpdate,
};

const EditItem = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(EditItemComponent));

export default EditItem;
