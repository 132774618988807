import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import NavigationComponent from './Navigation.component';

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({
});

const Navigation = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationComponent));

export default Navigation;
