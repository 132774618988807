export const actionEditTitle = (value, canSubmit) => ({
  type: 'EDIT_TITLE_VALUE',
  value,
  canSubmit
});

export const actionEditLink = (value, canSubmit) => ({
  type: 'EDIT_LINK_VALUE',
  value,
  canSubmit
});

export const actionGotResponse = (response) => ({
  type: 'ITEM_UPDATES',
  response
});

export const actionClose = () => ({
  c: console.info('HERE'),
  type: 'EDIT_CLOSE'
});

export const actionCallUpdate = (item) => (dispatch) =>
  fetch('/ajax/UpdateItem', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({
      id: item._id,
      title: item.title,
      link: item.link,
    })
  })
    .then(response => response.json())
    .then(json => dispatch(actionGotResponse(json)));
