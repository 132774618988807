import { connect } from 'react-redux';
import AddItemComponent from './AddItem.component';
import { actionUpdateLink, actionUpdateTitle, actionTrySubmit, addNewItem } from './AddItem.actions';

const mapStateToProps = (state) => ({
  canSubmit: (state.AddItem.canSubmit),
  doSubmit: (state.AddItem.canSubmit && state.AddItem.trySubmit),
  title: (state.AddItem.title),
  link: (state.AddItem.link),
});

const mapDispatchToProps = ({
  onTitleChange: actionUpdateTitle,
  onLinkChange: actionUpdateLink,
  onTrySubmit: actionTrySubmit,
  addNewItem,
});

const AddItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddItemComponent);

export default AddItem;
