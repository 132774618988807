const ToDoList = (state = { display: 'all', itemList: [], tryLoadData: false }, action) => {
  switch (action.type) {
    case 'UPDATE_TITLE_VALUE':
      return {
        ...state
      };
    case 'UPDATE_LIST':
      return {
        ...state,
        // eslint-disable-next-line no-underscore-dangle
        itemList: [
          action.response,
          ...state.itemList
        ]
      };
    case 'ITEM_REMOVE': {
      let found = false;
      let result = state;
      if (action.response.done === true) {
        for (let b = 0; b < state.itemList.length; b += 1) {
          // eslint-disable-next-line no-underscore-dangle
          if (state.itemList[b]._id === action.response._id) {
            found = b;
          }
        }

        if (found !== false) {
          result = {
            ...state,
            itemList: [
              ...state.itemList.slice(0, found),
              ...state.itemList.slice(found + 1)
            ]
          };
        }
      }
      return result;
    }
    case 'ITEM_UPDATES': {
      let found = false;
      for (let b = 0; b < state.itemList.length; b += 1) {
        // eslint-disable-next-line no-underscore-dangle
        if (state.itemList[b]._id === action.response._id) {
          found = b;
        }
      }
      let result = state;
      if (found !== false) {
        const itemListNew = [
          ...state.itemList
        ];
        itemListNew[found] = action.response;

        result = {
          ...state,
          itemList: itemListNew
        };
      }
      return result;
    }
    case 'LOAD_DATA':
      action.response.reverse();
      return {
        ...state,
        // eslint-disable-next-line no-underscore-dangle
        itemList: action.response.map((data) => ({ ...data, toogleAction: false })),
        tryLoadData: false,
      };
    case 'TRY_LOAD_DATA':
      return {
        ...state,
        tryLoadData: true,
      };
    case 'TODO_CALL_UPDATE':
      return {
        ...state,
        tryToDoUpdate: true,
      };
    default:
      return state;
  }
};

export default ToDoList;
