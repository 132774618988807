const AddItem = (state = { title: '', link: '', canSubmit: false, trySubmit: false }, action) => {
  switch (action.type) {
    case 'UPDATE_TITLE_VALUE':
      return {
        ...state,
        title: action.value,
        trySubmit: false,
        canSubmit: action.canSubmit,
      };
    case 'UPDATE_LINK_VALUE':
      return {
        ...state,
        link: action.value,
        trySubmit: false,
        canSubmit: action.canSubmit,
      };
    case 'TRY_SUBMIT_FORM':
      return {
        ...state,
        trySubmit: true,
      };
    case 'UPDATE_LIST':
      return {
        ...state,
        title: '',
        link: '',
        trySubmit: false,
        canSubmit: true,
      };
    default:
      return state;
  }
};

export default AddItem;
