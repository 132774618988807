import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Switch, Route  } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';


import App from './components/app/App';
import { store, history } from './reducers';

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
          <Switch>
            <Route exact path="/:filter?" component={App} />
          </Switch>
      </div>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
