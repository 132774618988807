import { connect } from 'react-redux';
import ToDoComponent from './ToDo.component';
// import { doUpdateItem } from '../toDo/ToDo.actions';

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: ownProps.onClick,
  onEdit: ownProps.onEdit,
  onRemove: ownProps.onRemove,
//  doUpdateItem,
});

const ToDo = connect(
  mapStateToProps,
  mapDispatchToProps
)(ToDoComponent);

export default ToDo;
